import React from 'react';

import DefaultPagination from '../../../Pagination';
import type { PaginationProps } from '../../../Pagination';
import styles from './Pagination.module.css';

const Pagination = (props: PaginationProps) => (
    <DefaultPagination
        {...props}
        themeStyles={styles}
    />
);

export default Pagination;
