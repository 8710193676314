import { useMemo } from 'react';

const DOTS = '...';

const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

interface UsePaginationProps {
    totalCount: number;
    pageSize: number;
    siblingCount: number;
    currentPage: number;
    visibleElement: number;
    pageSizeOffset: number;
}

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage, visibleElement, pageSizeOffset = 0 }: UsePaginationProps) => {
    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil((totalCount - pageSizeOffset) / pageSize);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

        /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
        const shouldShowLeftDots = leftSiblingIndex > visibleElement - 1;
        const shouldShowRightDots = rightSiblingIndex <= totalPageCount - (visibleElement - 1);

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = visibleElement + siblingCount * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = visibleElement + siblingCount * siblingCount;
            let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
        return range(1, totalPageCount);
    }, [totalCount, pageSizeOffset, pageSize, siblingCount, currentPage, visibleElement]);

    return paginationRange;
};
